<template>
  <div class="cars" style="cursor:pointer">
    <v-card
      width="370"
      height="390"
      hover
      @click="goToCarPage()"
      style="border-radius: 15px"
    >
      <v-card-title class="carTitle">{{ car.model }}</v-card-title>

      <v-card-subtitle
        class="text-start subTitle"
        style="display: flex;justify-content: space-between;padding-right: 10px;"
      >
        {{ minPriceText }} ₽
        <!-- <v-spacer></v-spacer> -->
        <v-chip small :color="chipColor" style="color:white ">
          {{ car.status.name }} {{ car.status.date }}</v-chip
        >
      </v-card-subtitle>
      <div>
        <v-img :src="car.imgLink" max-width="370" height="250"
          ><div
            v-if="car.transmission != 'Автомат'||car.model.toLowerCase().includes('lada')"
            style="position: absolute; right: 0.4rem; top: 0.2rem;"
          >
            <v-chip style="color:white" small :color="gearColor">{{
              car.transmission
            }}</v-chip>
          </div></v-img
        >
      </div>
      <v-card-actions style="width: 100%;">
        <v-btn class="car_btn" text>
          <span>Забронировать</span>
        </v-btn>
        <!--<v-btn
          text
          @click="reserve()"
          outlined
          color="blue"
          :loading="spinnerBooking"
        >
          <v-icon>mdi-check-bold</v-icon>
          <span>Забронировать</span>
        </v-btn>-->
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import cyrillicToTranslit from "cyrillic-to-translit-js";

export default {
  name: "CarsCardVidjet",
  data() {
    return { spinnerBooking: false };
  },
  props: {
    car: Object,
    querylink: String
  },
  methods: {
    goToCarPage() {
      // this.dialog = true
      const nameCar = (
        cyrillicToTranslit().transform(this.car.model, "_") + `_${this.car.id}`
      ).toLowerCase();
      window
        .open(
          `https://app.a-prokat.com/car/${nameCar}?${this.querylink}`,
          "_blank"
        )
        ?.focus();
    },
    async reserve() {}
    // startBooking() {
    //     // console.log(this.car.contactUs);
    //     // this.$router.push(this.car.contactUs)
    //     // window.location = this.car.contactUs
    //     // this.updateBookingState(true)
    // },
  },
  computed: {
    minPriceText() {
      if (this.car?.currentTariffFull) {
        if (this.car?.currentTariffFull?.[15]?.[0] == 2) {
          return `${this.car?.price}`;
        } else {
          return `${this.car?.minPrice}`;
        }
      } else {
        return this.car?.minPrice;
      }
      //return this.car?.currentTariffFull?.[15]?.[0]==2?`до ${this.car.price}`:`от ${this.car.minPrice}`
    },
    chipColor() {
      if (this.car.status.id == 0) return "green";
      if (this.car.status.id == 1) return "blue";
      if (this.car.status.id == 2) return "orange";
      if (this.car.status.id == 3) return "purple";
      if (this.car.status.id == 4) return "red darken-4";
      return "grey";
    },
    gearColor() {
      if (this.car.transmission == "Автомат") return "#00ACC1";
      if (this.car.transmission == "Механика") return "#00897B";
      return "grey";
    }
  }
};
</script>

<style scoped>
* {
  color: #1c1b29;
}
.cars {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-weight: 400;
}
.carTitle {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-weight: 700;
  color: #1c1b29;
}
.car_btn {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  width: 100%;
  font-weight: 500;
  background-color: #ffd249;
  border-radius: 7px;
  color: #1c1b29;
}
.car_btn:hover {
  background-color: #f1be22;
}
</style>
